<template>
  <div class="zplayer" ref="playerWrap">
    <div class="zplayer-video-wrap">
      <div class="zplayer-video-mask" ref="mask"></div>
      <video
        preload="true"
        ref="video"
        x5-video-player-type="h5-page"
        webkit-playsinline="true"
        playsinline=""
        x-webkit-airplay="allow"
        mtt-playsinline="true"
        :src="options.src"
        poster="@/assets/img/video_fmt.jpg"
      >
      </video>
    </div>
    <div class="zplayer-loading" v-show="showLoadingIcon">
      <svg
        version="1.1"
        id="loader-1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="40px"
        height="40px"
        viewBox="0 0 50 50"
        style="enable-background: new 0 0 50 50"
        xml:space="preserve"
      >
        <path
          fill="#fff"
          d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
        ></path>
      </svg>
    </div>
    <div class="play-state-animation" ref="playedAnimation">
      <svg
        class="pause-btn"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox="0 0 17 32"
      >
        <path
          d="M14.080 4.8q2.88 0 2.88 2.048v18.24q0 2.112-2.88 2.112t-2.88-2.112v-18.24q0-2.048 2.88-2.048zM2.88 4.8q2.88 0 2.88 2.048v18.24q0 2.112-2.88 2.112t-2.88-2.112v-18.24q0-2.048 2.88-2.048z"
        ></path>
      </svg>
      <svg
        class="play-btn"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox="0 0 16 32"
      >
        <path
          d="M15.552 15.168q0.448 0.32 0.448 0.832 0 0.448-0.448 0.768l-13.696 8.512q-0.768 0.512-1.312 0.192t-0.544-1.28v-16.448q0-0.96 0.544-1.28t1.312 0.192z"
        ></path>
      </svg>
    </div>
    <div class="zplayer-controller active" ref="controller">
      <div class="zplayer-controller-top">
        <div class="zplayer-bar-wrap" ref="controllBar">
          <div class="zplayer-bar">
            <svg
              ref="loadedBar"
              class="zplayer-loaded"
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              v-html="loadedBarHtml"
            ></svg>
            <div
              class="zplayer-played"
              :style="{ width: playedBarWidth }"
            ></div>
            <span
              class="zplayer-thumb"
              ref="thumb"
              :style="{ left: `${thumbLeft}px` }"
            ></span>
          </div>
        </div>
      </div>
      <div class="zplayer-controller-bottom">
        <div class="zplayer-icon-left">
          <div class="zplayer-icon zplayer-play-btn" ref="playBtn">
            <svg
              v-show="showPlayBtn"
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              viewBox="0 0 17 32"
            >
              <path
                d="M14.080 4.8q2.88 0 2.88 2.048v18.24q0 2.112-2.88 2.112t-2.88-2.112v-18.24q0-2.048 2.88-2.048zM2.88 4.8q2.88 0 2.88 2.048v18.24q0 2.112-2.88 2.112t-2.88-2.112v-18.24q0-2.048 2.88-2.048z"
              ></path>
            </svg>
            <svg
              v-show="!showPlayBtn"
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              viewBox="0 0 16 32"
            >
              <path
                d="M15.552 15.168q0.448 0.32 0.448 0.832 0 0.448-0.448 0.768l-13.696 8.512q-0.768 0.512-1.312 0.192t-0.544-1.28v-16.448q0-0.96 0.544-1.28t1.312 0.192z"
              ></path>
            </svg>
          </div>
          <div class="zplayer-volume-wrap" ref="volumeWrap">
            <div class="zplayer-icon zplayer-volume-btn" ref="volumeBtn">
              <svg
                v-show="!showVolume"
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                viewBox="0 0 21 32"
              >
                <path
                  d="M13.728 6.272v19.456q0 0.448-0.352 0.8t-0.8 0.32-0.8-0.32l-5.952-5.952h-4.672q-0.48 0-0.8-0.352t-0.352-0.8v-6.848q0-0.48 0.352-0.8t0.8-0.352h4.672l5.952-5.952q0.32-0.32 0.8-0.32t0.8 0.32 0.352 0.8z"
                ></path>
              </svg>
              <svg
                v-show="showVolume"
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                viewBox="0 0 21 32"
              >
                <path
                  d="M13.728 6.272v19.456q0 0.448-0.352 0.8t-0.8 0.32-0.8-0.32l-5.952-5.952h-4.672q-0.48 0-0.8-0.352t-0.352-0.8v-6.848q0-0.48 0.352-0.8t0.8-0.352h4.672l5.952-5.952q0.32-0.32 0.8-0.32t0.8 0.32 0.352 0.8zM20.576 16q0 1.344-0.768 2.528t-2.016 1.664q-0.16 0.096-0.448 0.096-0.448 0-0.8-0.32t-0.32-0.832q0-0.384 0.192-0.64t0.544-0.448 0.608-0.384 0.512-0.64 0.192-1.024-0.192-1.024-0.512-0.64-0.608-0.384-0.544-0.448-0.192-0.64q0-0.48 0.32-0.832t0.8-0.32q0.288 0 0.448 0.096 1.248 0.48 2.016 1.664t0.768 2.528z"
                ></path>
              </svg>
            </div>
            <div class="zplayer-volume">
              <div class="zplayer-volume-bar-wrap" ref="volumeBarWrap">
                <div
                  class="zplayer-volume-bar"
                  :style="{ width: volumeBarWidth }"
                >
                  <div class="zplayer-volume-thumb" ref="volumeBarThumb"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="zplayer-time">
            <span class="zplayer-ctime">{{ ctime }}</span
            >&nbsp;/&nbsp;<span class="zplayer-dtime">{{ dtime }}</span>
          </div>
        </div>
        <div class="zplayer-icon-right">
          <div class="zplayer-icon zplayer-fullscreen-btn" ref="fsBtn">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              viewBox="0 0 32 33"
            >
              <path
                d="M6.667 28h-5.333c-0.8 0-1.333-0.533-1.333-1.333v-5.333c0-0.8 0.533-1.333 1.333-1.333s1.333 0.533 1.333 1.333v4h4c0.8 0 1.333 0.533 1.333 1.333s-0.533 1.333-1.333 1.333zM30.667 28h-5.333c-0.8 0-1.333-0.533-1.333-1.333s0.533-1.333 1.333-1.333h4v-4c0-0.8 0.533-1.333 1.333-1.333s1.333 0.533 1.333 1.333v5.333c0 0.8-0.533 1.333-1.333 1.333zM30.667 12c-0.8 0-1.333-0.533-1.333-1.333v-4h-4c-0.8 0-1.333-0.533-1.333-1.333s0.533-1.333 1.333-1.333h5.333c0.8 0 1.333 0.533 1.333 1.333v5.333c0 0.8-0.533 1.333-1.333 1.333zM1.333 12c-0.8 0-1.333-0.533-1.333-1.333v-5.333c0-0.8 0.533-1.333 1.333-1.333h5.333c0.8 0 1.333 0.533 1.333 1.333s-0.533 1.333-1.333 1.333h-4v4c0 0.8-0.533 1.333-1.333 1.333z"
              ></path>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="zplayer-notice" :class="{ active: notice != '' }">
      {{ notice }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'zplayer',
  data() {
    return {
      showVolume: true, //显示声音
      showPlayBtn: false,
      options: {},
      thumbLeft: 0,
      showLoadingIcon: false,
      volumeBarWidth: 1,
      playedBarWidth: 0,
      ctime: '00:00', //当前播放的时间
      dtime: '00:00', //播放总时长
      notice: '',
      loadedBarHtml: '',
      playBtnClickNum: 0,
    }
  },
  props: {
    opt: {
      type: Object,
      default() {
        return {}
      },
    },
    firstUpLoad:{
      type:Boolean,
      default() {
        return false
      },
    }
  },
  watch:{
    firstUpLoad: {
      handler: function (n) {
        if(n){
          this.playBtnClickNum = 0;
        } 
      },
      // 开启深度监听：只要obj中的任何一个属性发生改变，都会触发相应的代码
      deep: true
    }
  },
  mounted() {
    
    // 默认配置参数
    let defaultOptions = {
      src: '',
      poster: '', //封面图
      seekStatus: false, //是否能被拖动
      blobSrc: false,
      controllBarMode: 'hover', //控制栏显示或隐藏方法  hover click
    }
    // 用户不可配置参数
    let unModityOptions = {
      isIOS: !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
      isMoblie: !!navigator.userAgent.match(/AppleWebKit.*Mobile.*/),
      currentTime: 0,
      lastVolume: 1,
      showController: this.opt.controllBarMode == 'click' ? true : false,
    }
    this.options = Object.assign(defaultOptions, this.opt, unModityOptions)
    this.init()
    this.optionsConfig()
    this.btnBindFunc()
  },
  methods: {
    init() {
      // 声明变量
      this.player = this.$refs.video;
      this.playerWrap = this.$refs.playerWrap
      this.controller = this.$refs.controller
      this.mask = this.$refs.mask
      this.playBtn = this.$refs.playBtn
      this.fsBtn = this.$refs.fsBtn
      this.loadedBar = this.$refs.loadedBar
      this.thumb = this.$refs.thumb
      this.volumeWrap = this.$refs.volumeWrap
      this.volumeBarWrap = this.$refs.volumeBarWrap
      this.volumeBarThumb = this.$refs.volumeBarThumb
      this.controllBar = this.$refs.controllBar
      this.playedAnimation = this.$refs.playedAnimation
      this.volumeBtn = this.$refs.volumeBtn

      this.isSeeking = false
      if (this.options.isMoblie) {
        this.playerWrap.classList.add('phone')
      }
      if (this.options.isIOS) {
        this.playerWrap.classList.add('ios-app')
      }
      this._setVolumeBarWidth(this.player.volume)
      // 添加监听事件
    },
    // 选项配置  根据options作出一些事件的添加
    optionsConfig() {
      if (this.options.controllBarMode == 'hover') {
        this.playerWrap.addEventListener('mouseenter', () => {
          if (this.options.showController) return false
          this.controller.classList.add('active')
        })
        this.playerWrap.addEventListener('mouseleave', () => {
          if (this.options.showController) return false
          this.controller.classList.remove('active')
        })
      }
      if (this.options.controllBarMode == 'click') {
        this.mask.addEventListener('click', () => {
          this.options.showController = !this.options.showController
          this.options.showController
            ? this.controller.classList.add('active')
            : this.controller.classList.remove('active')
        })
      }
    },
    // 给按钮绑定方法
    btnBindFunc() {
      // 播放、暂停按钮
      this.playBtn.addEventListener('click', () => {
        this.togglePlay()
      })
      // 全屏按钮
      this.fsBtn.addEventListener('click', () => {
        this.fullScreen()
      })
      this.player.addEventListener('loadedmetadata', () => {
        this.ctime = this._changeTime(0)
        this.dtime = this._changeTime(this.player.duration)
      })
      // 监听视频是否正在播放
      this.player.addEventListener('playing', () => {
        if (this.playBtnClickNum == 0) {
          this.$emit('firstplay');
          this.$emit('videoplay',this.player.duration);
        }
        this.playBtnClickNum++
        this.loading(false)

      })
      // 监听视频是否可以播放
      this.player.addEventListener('canplay', () => {
        this.loading(false)
        this.$emit('changeUrl', {
          options: this.options,
          player: this.player,
        })
      })
      // 监听视频是否需要等待
      this.player.addEventListener('waiting', () => {
        this.loading(true)
      })
      this.player.addEventListener('loadedmetadata', () => {
        this.$emit('loadedmetadata')
      })

      // 监听进度条进度
      this.player.addEventListener('timeupdate', () => {
        this._setCurrentTime(this.player.currentTime, false)
        this.options.currentTime = this.player.currentTime
        this.ctime = this._changeTime(this.player.currentTime)
        this.dtime = this._changeTime(this.player.duration)
        this.$emit('timeupdate', this.player.currentTime)
      })
      // 监听播放是否被终止  在win7的IE11上无法被触发
      this.player.addEventListener('abort', () => {
        this._clearAll()
        // 播放停止时触发
        this.$emit('videoabort',this.player.duration);
      })
      this.player.addEventListener('pause', () => {
        this.$emit('videopause',this.player.duration);
      })
      this.player.addEventListener('play', () => {

      })
      // 监听视频是否播完
      this.player.addEventListener('ended', () => {
        let data = {
          duration: this.player.duration,
        }
        this.$emit('ended', data)
        // this.player.currentTime = 0;
      })
      // 监听视频错误事件
      this.player.addEventListener('error', (e) => {
        let code = this.player.error.code
        if (code == 1) {
          //用户终止视频
        } else if (code == 2) {
          //网络错误
          let nState = this.player.networkState
          let errArr = [
            '此元素未初始化',
            '网络异常，请检查网络',
            '正在下载数据',
            '没有找到资源',
          ]
          this._noticeSwitch(errArr[nState])
        } else if (code == 3) {
          // 解码错误
          this._noticeSwitch('视频解码错误')
        } else if (code == 4) {
          // URL无效
          this._noticeSwitch('无效的视频链接')
        }
        // this.player.currentTime = 0;
      })
      // 监听视频的缓冲进度
      this.player.addEventListener('progress', (e) => {
        this.drawLoadedBar()
      })
      this._volumeBtnListener()
      this._volumeBarListener()
      this.seekBarListener()
      this.playedAnimationListener()
    },
    // 消息开关
    _noticeSwitch(str) {
      this.notice = str ? str : ''
    },
    // 监听声音条变动  完成
    _volumeBarListener() {
      const l = Math.floor(this.volumeBarWrap.getBoundingClientRect().left)
      const mousemoveFunc = (e) => {
        let w = e.pageX - l - 5
        if (w < 0) {
          this._setVolumeBarWidth(0)
        } else if (w > 45) {
          this._setVolumeBarWidth(1)
        } else {
          this._setVolumeBarWidth(Number((w / 45).toFixed(2)))
        }
      }
      const mouseupFunc = (e) => {
        this.volumeWrap.classList.remove('active')
        let w
        if (e.pageX - l < 0) {
          w = 0
        } else if (e.pageX - l > 45) {
          w = 45
        } else {
          w = e.pageX - l - 5
        }
        this._setVolumeBarWidth(Number((w / 45).toFixed(2)))
        document.removeEventListener('mousemove', mousemoveFunc)
        document.removeEventListener('mouseup', mouseupFunc)
        this.options.showController = false
      }
      const clickFunc = (e) => {
        this._setVolumeBarWidth(Number((e.offsetX / 45).toFixed(2)))
      }
      this.volumeBarThumb.addEventListener('click', (e) => {
        event.preventDefault()
        event.stopPropagation()
      })
      this.volumeBarThumb.addEventListener('mousedown', (e) => {
        event.preventDefault()
        event.stopPropagation()
        this.volumeWrap.classList.add('active')
        this.options.showController = true
        document.addEventListener('mousemove', mousemoveFunc)
        document.addEventListener('mouseup', mouseupFunc)
      })
      this.volumeBarWrap.addEventListener('click', clickFunc)
    },
    // 监听声音按钮变动 完成
    _volumeBtnListener() {
      this.volumeBtn.addEventListener('click', () => {
        this.player.volume > 0
          ? this._setVolumeBarWidth(0)
          : this._setVolumeBarWidth(this.options.lastVolume)
      })
    },
    // 监听开始暂停动画结束监听器 完成
    playedAnimationListener() {
      this.playedAnimation.addEventListener('animationend', () => {
        this._clearVideoStateAni()
      })
      this.playedAnimation.addEventListener('animationend', () => {
        this._clearVideoStateAni()
      })
    },
    // 开始暂停动画开关 完成
    playedAnimationSwitch(data) {
      data
        ? this.playedAnimation.classList.add('played')
        : this.playedAnimation.classList.add('paused')
    },
    // 绘制加载进度条 完成
    drawLoadedBar() {
      let str = '',
        duration = this.player.duration,
        w = this.loadedBar.getBoundingClientRect().width
      for (let i = 0; i < this.player.buffered.length; i++) {
        let x1 = (this.player.buffered.start(i) / duration) * w
        let x2 = (this.player.buffered.end(i) / duration) * w
        str += `<line x1="${x1}" y1="0" x2="${x2}" y2="0"/>`
      }
      this.loadedBarHtml = str
    },
    // 监听用户移动进度条 完成
    seekBarListener() {
      if (!this.options.seekStatus) return false
      const l = Math.floor(this.controllBar.getBoundingClientRect().left)
      const w = Math.floor(this.controllBar.getBoundingClientRect().width)
      const clickFunc = (e) => {
        if (this.isSeeking) return false
        let w = this.controllBar.getBoundingClientRect().width
        let currentTime = Math.floor((e.offsetX / w) * this.player.duration)
        this._setCurrentTime(currentTime)
        this._videoPlay()
      }
      const mousemoveFunc = (e) => {
        let x
        if (e.pageX - l < 0) {
          x = '0px'
        } else if (e.pageX - l > w) {
          x = `${w}px`
        } else {
          x = `${e.pageX}px`
        }
        this.thumbLeft = x
      }
      const mouseupFunc = (e) => {
        e.preventDefault()
        e.stopPropagation()
        let x
        if (e.pageX - l < 0) {
          x = 0
        } else if (e.pageX - l > w) {
          x = w
        } else {
          x = e.pageX - l
        }
        this.volumeWrap.classList.remove('active')
        this.controllBar.addEventListener('click', clickFunc)
        this.thumb.classList.remove('mousedown')
        let currentTime = Math.floor((x / w) * this.player.duration)
        this._setCurrentTime(currentTime)
        document.removeEventListener('mousemove', mousemoveFunc)
        document.removeEventListener('mouseup', mouseupFunc)
        this.isSeeking = false
        this.options.showController = false
      }
      this.thumb.addEventListener('mousedown', (e) => {
        e.stopPropagation()
        e.preventDefault()
        this.isSeeking = true
        this.options.showController = true
        this.thumb.classList.add('mousedown')
        document.addEventListener('mousemove', mousemoveFunc)
        document.addEventListener('mouseup', mouseupFunc)
        this.controllBar.removeEventListener('click', clickFunc)
      })
      this.thumb.addEventListener('click', (e) => {
        e.stopPropagation()
        e.preventDefault()
      })
      this.controllBar.addEventListener('click', clickFunc)
    },
    // 设置音量进度条的宽度
    _setVolumeBarWidth(ratio) {
      this.volumeBarWidth = `${ratio * 100}%`
      this.player.volume = ratio
      this.showVolume = ratio == 0 ? false : true
      if (ratio != 0) {
        this.options.lastVolume = ratio
      }
    },
    // 视频加载标识
    loading(status) {
      this.showLoadingIcon = status ? true : false
    },
    // 改变地址
    changeUrl(url) {
      this._clearAll()
      this.options.src = url
      this.loading(true)
    },
    //开始、暂停按钮 完成
    togglePlay() {
      this.player.paused ? this._videoPlay() : this._videoPause()
    },
    // 视频播放 完成
    _videoPlay() {
      this._clearVideoStateAni()
      this.showPlayBtn = true
      this.player.paused ? this.playedAnimationSwitch(true) : ''
      this.player.play()
    },
    // 视频暂停 完成
    _videoPause() {
      this._clearVideoStateAni()
      this.player.pause()
      this.showPlayBtn = false
      this.playedAnimationSwitch(false)
    },
    // 全屏
    fullScreen() {
      if (!this.playerWrap.classList.contains('full-screen')) {
        if (this.playerWrap.classList.contains('phone')) {
          this.playerWrap.classList.add('full-screen')
          return false
        }
        var el = this.playerWrap
        var rfs =
          el.requestFullScreen ||
          el.webkitRequestFullScreen ||
          el.mozRequestFullScreen ||
          el.msRequestFullScreen
        if (typeof rfs != 'undefined' && rfs) rfs.call(el)
        this.playerWrap.classList.add('full-screen')
      } else {
        if (this.playerWrap.classList.contains('phone')) {
          this.playerWrap.classList.remove('full-screen')
          return false
        }
        var rfs =
          document.exitFullscreen ||
          document.msExitFullscreen ||
          document.mozCancelFullScreen ||
          document.webkitExitFullscreen
        if (typeof rfs != 'undefined' && rfs) rfs.call(document)
        this.playerWrap.classList.remove('full-screen')
      }
    },
    // 改变控制栏显示的时间
    _changeTime(time) {
      let str = ''
      let t = Math.floor(time)
      let h = Math.floor(t / 3600)
        ? '0' + Math.floor(t / 3600)
        : Math.floor(t / 3600)
      let m =
        Math.floor((t - h * 3600) / 60) < 10
          ? '0' + Math.floor((t - h * 3600) / 60)
          : Math.floor((t - h * 3600) / 60)
      let s =
        Math.floor(t - h * 3600 - m * 60) < 10
          ? '0' + Math.floor(t - h * 3600 - m * 60)
          : Math.floor(t - h * 3600 - m * 60)
      if (this.player.duration > 3600) {
        str = `${h}:${m}:${s}`
      } else if (this.player.duration > 0) {
        str = `${m}:${s}`
      } else {
        str = `00:00`
      }
      return str
    },
    // 设置视频当前的时间;
    _setCurrentTime(time, change = true) {
      let ratio = this.player.duration ? (time / this.player.duration) * 100 : 0
      if (change) {
        this.player.currentTime = time
      }
      this.playedBarWidth = `${ratio}%`
      if (!this.isSeeking) {
        let w = this.controllBar.getBoundingClientRect().width
        this.thumbLeft = `${(ratio * w) / 100}`
      }
    },
    // 清除当前观看时间
    _clearCurrentTime() {
      this._setCurrentTime(0)
    },
    _clearLoadedBar() {
      this.loadedBarHtml = ''
    },
    // 清除开始/暂停动画的类名 完成
    _clearVideoStateAni() {
      this.playedAnimation.classList.remove('played')
      this.playedAnimation.classList.remove('paused')
    },
    // 清除所有状态，回复最开始的状态
    _clearAll() {
      this._clearVideoStateAni()
      this._clearCurrentTime()
      this._clearLoadedBar()
      this._noticeSwitch('')
      this._videoPause()
      this.playBtnClickNum = 0
    },
  },
}
</script>

<style lang="less">
.zplayer {
  position: relative;
  overflow: hidden;
  background-color: #000;
  max-width: 100%;
  height: 100%;
  &.phone.full-screen {
    position: fixed !important;
    z-index: 1500 !important;
    width: 100% !important;
    height: 100% !important;

    video {
      width: 100% !important;
      height: 100% !important;
    }
    .zplayer-controller {
      position: fixed !important;
      z-index: 1501 !important;
      left: 0 !important;
      bottom: 0 !important;
    }
  }
  &.ios-app.full-screen {
    position: fixed !important;
    z-index: 1500 !important;
    width: 100% !important;
    height: 100% !important;

    video {
      width: 100% !important;
      height: 100% !important;
    }
  }

  .zplayer-video-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    video {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: contain;
    }
    .zplayer-video-mask {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
    }
  }

  .zplayer-loading {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
    svg {
      animation: rotate 0.8s linear infinite;
      opacity: 0.8;
    }
  }

  .zplayer-controller {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 90px;
    width: 100%;
    background-image: linear-gradient(transparent 0%, rgba(20, 20, 20, 1) 100%);
    z-index: 99;
    transform: translateY(100%);
    transition: transform 0.3s;
    &.active {
      transform: translateY(0%);
    }
    .zplayer-controller-top {
      width: 100%;
      height: 26px;
      padding: 0 40px;
      box-sizing: border-box;

      .zplayer-bar-wrap {
        padding: 10px 0;
        width: 100%;
        height: 6px;
        box-sizing: content-box;
        cursor: pointer;

        &:hover {
          .zplayer-bar .zplayer-played + .zplayer-thumb {
            transform: scale(1);
          }
        }

        .zplayer-bar {
          width: 100%;
          height: 100%;
          background-color: rgba(245, 245, 245, 0.2);
          position: relative;
          border-radius: 999px;

          .zplayer-loaded {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background-color: transparent;
            line {
              stroke-width: 6;
              stroke: rgba(255, 255, 255, 0.35);
              fill: none;
              shape-rendering: crispEdges;
              line-height: 1;
            }
          }

          .zplayer-played {
            width: 0;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.9);
            position: absolute;
            left: 0;
            top: 0;
          }
          .zplayer-thumb {
            position: absolute;
            top: 0;
            left: 0;
            margin-top: -4px;
            margin-left: -5px;
            height: 11px;
            width: 11px;
            border-radius: 50%;
            cursor: pointer;
            transition: transform 0.3s ease-in-out;
            transform: scale(0);
            background-color: rgba(255, 255, 255, 0.9);
            &.mousedown {
              transform: scale(1) !important;
            }
          }
        }
      }
    }

    .zplayer-controller-bottom {
        width: 100%;
        height: 64px;
        position: absolute;
        bottom: 10px;
        left: 0;
        box-sizing: border-box;

        .zplayer-icon-left {
            float: left;
            height: 100%;
            padding-left: 20px;
            display: flex;
            align-items: center;
        }

        .zplayer-icon-right {
            height: 100%;
            float: right;
            padding-right: 20px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
        }

        .zplayer-volume-wrap {
            height: 48px;
            display: inline-block;
            vertical-align: middle;
            margin-left: 10px;
            width: 100px;
            position: relative;
            display: flex;
            align-items: center;
            width: 2.5rem;
            &.active {
                .zplayer-volume {
                    transform: scale(1) !important;
                }
            }
            &:hover {
                .zplayer-volume {
                    transform: scale(1);
                }
            }
            .zplayer-volume-btn:hover + .zplayer-volume {
                transform: scale(1);
            }
            .zplayer-volume {
                display: inline-block;
                vertical-align: middle;
                transition: transform 0.4s;
                transform: scale(0);
                transform-origin: left center;
                .zplayer-volume-bar-wrap {
                    width: 90px;
                    height: 6px;
                    border-radius: 99px;
                    margin-left: 10px;
                    position: relative;
                    background-color: rgba(200, 200, 200, 0.6);
                    cursor: pointer;
                    .zplayer-volume-bar {
                        width: 0;
                        height: 100%;
                        position: absolute;
                        background-color: rgba(225, 225, 225, 1);
                        .zplayer-volume-thumb {
                            position: absolute;
                            top: 0;
                            right: 10px;
                            margin-top: -8px;
                            margin-right: -20px;
                            height: 22px;
                            width: 22px;
                            border-radius: 50%;
                            cursor: pointer;
                            transition: all 0.3s ease-in-out;
                            background-color: rgba(225, 225, 225, 1);
                        }
                    }
                }
            }
        }

        .zplayer-time {
            display: inline-block;
            vertical-align: middle;
            height: 24px;
            margin-left: 10px;
            color: #fff;
            opacity: 0.8;
            font-size: 30px;
            line-height: 24px;
        }
    }
  }
  .play-state-animation {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 70px;
    height: 70px;
    border-radius: 999px;
    z-index: 4;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
    animation-duration: 0.6s;
    &.played {
      animation-name: playState;
      display: block;
      .play-btn {
        display: block;
      }
    }
    &.paused {
      animation-name: playState;
      display: block;
      .pause-btn {
        display: block;
      }
    }
    svg {
      width: 40px;
      height: 40px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      fill: rgba(255, 255, 255, 0.9);
      display: none;
    }
  }
  .zplayer-notice {
    opacity: 0;
    position: absolute;
    bottom: 60px;
    left: 20px;
    font-size: 14px;
    border-radius: 2px;
    background: rgba(28, 28, 28, 0.9);
    padding: 7px 20px;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    color: #fff;
    pointer-events: none;
    z-index: 10;
    &.active {
      opacity: 0.9;
    }
  }
}

.zplayer-icon {
  width: 52px;
  height: 48px;
  box-sizing: border-box;
  opacity: 0.8;
  transition: opacity 0.5s;
  display: block !important;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }

  path {
    fill: #fff;
  }
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

@keyframes playState {
  to {
    transform: translate(-50%, -50%) scale(1.5);
    opacity: 0;
  }
}
</style>
