<template>
  <!-- 待学习 -->
  <div class="learningCourses">
    <van-nav-bar :title="studyTitle"
                 left-text=""
                 left-arrow
                 @click-left="onClickLeft"
                 @click-right="onClickRight"
                 :fixed="true"
                 style="height: 1.21875rem" />
    <div class="learningContainer">
      <!-- pdf内置播放器 -->
      <pdfView :pdf="pdf"></pdfView>
      <div class="videoDiv"
           style="
          width: 100%;
          height: 6.1867rem;
          position: fixed;
          left: 0;
          top: 1.21875rem;
          z-index: 999;
        ">
        <video width="100%"
               controls
               controlslist="nodownload"
               style="height: 6.1867rem; background: #000"
               v-if="videoTagStatus"
               poster="@/assets/img/video_fmt.jpg">
          <source :src="videoSrc"
                  type="video/mp4" />
          {{ $t("learningCourses.CourseTxt2") }}
        </video>
        <zplayer v-if="videoStatus"
                 ref="zplayer"
                 :opt="playerOptions"
                 :firstUpLoad="firstUpLoad"
                 @ended="ended"
                 @changeUrl="addSignIn"
                 @timeupdate="timeUpdate"
                 @firstplay="firstplay"
                 @videopause="videopause"
                 @videoplay="videoplay"
                 @videoabort="videoabort"></zplayer>
        <div v-if="pdfStatus"
             class="pdfClass">
          <a href="javascript:;"
             @click="visitPdf">
            <div class="pdfDiv">
              <div class="pdfContent">
                <svg-icon icon-class="pdf"></svg-icon>
                <p>{{ $t("learningCourses.CourseTxt3") }}</p>
              </div>
            </div>
          </a>
        </div>
        <div v-if="ifmUrl"
             class="pdfClass">
          <a href="javascript:;"
             @click="visitLink">
            <div class="pdfDiv">
              <div class="pdfContent">
                <svg-icon icon-class="link"></svg-icon>
                <p>{{ $t("learningCourses.CourseTxt11") }}</p>
              </div>
            </div>
          </a>
        </div>
        <div v-if="audioStatus"
             style="
            width: 100%;
            height: 6.1867rem;
            background: #000;
            display: flex;
            justify-content: center;
            align-items: center;
          ">
          <audio ref="audioRefDom"
                 controlslist="nodownload"
                 :src="audioSrc"
                 controls="controls"
                 style="max-width: 100%"></audio>
        </div>
      </div>
      <div class="tabsDiv"
           style="margin-top: 7.40545rem">
        <van-tabs v-model="active"
                  color="#2B8DF0"
                  swipeable
                  animated>
          <van-tab :title="$t('learningCourses.CourseTxt12')">
            <van-list v-model="loading"
                      :finished="finished"
                      :finished-text="$t('learningCourses.CourseTxt5')"
                      @load="onLoad">
              <div v-for="(item, index) in list"
                   :key="index">
                <h2>{{ item[0].stagename }}</h2>
                <van-cell class="CoursesList"
                          @click="changeCourseware(val, index, key)"
                          :class="{ activeCls: val.active }"
                          v-for="(val, key) in item"
                          :key="key">
                  <div class="infoExamIcon"
                       v-if="val.isfinish">
                    <img src="@/assets/img/trained.png"
                         alt="" />
                  </div>
                  <div class="leftbox">
                    <img v-if="val.cw_bg_url != ''"
                         v-lazy="val.cw_bg_url" />
                    <img v-else
                         src="@/assets/img/default_bgimg.jpg"
                         alt="" />
                    <div class="kjlx">
                      {{ val.cwtype }}
                    </div>
                  </div>
                  <div class="info">
                    <div class="footer">
                      <span class="stageName"> {{ val.cwname }} </span>
                    </div>
                    <span class="description">{{ val.cwdesp }}</span>
                    <div class="top">
                      <div class="right">
                        <span class="length">{{ val.studymin
                          }}{{ $t("learningCourses.CourseTxt10") }}</span>
                      </div>
                    </div>
                  </div>
                </van-cell>
              </div>
            </van-list>
          </van-tab>
          <van-tab :title="$t('learningCourses.CourseTxt6')">
            <van-cell class="examList">
              <div class="info">
                <div class="footer">
                  <span class="courseName">{{ studyData.coursename }}</span>
                  <div class="right">
                    <span class="score">{{ studyData.coursecredit
                      }}{{ $t("learningCourses.CourseTxt7") }}</span>
                  </div>
                </div>
                <span class="lecturerName">{{ $t("learningCourses.CourseTxt8")
                  }}{{ studyData.teacher_empname }}</span>
                <div class="footer">
                  <span class="date">
                    {{ studyData.studybt }} - {{ studyData.studyet }}</span>
                  <div class="right">
                    <span class="duration">{{ studyData.coursehour
                      }}{{ $t("learningCourses.CourseTxt9") }}</span>
                  </div>
                </div>
              </div>
            </van-cell>
            <ul class="descriptionUl">
              <li>
                {{ studyData.coursedesp }}
              </li>
            </ul>
          </van-tab>
        </van-tabs>
      </div>
    </div>
    <div class="linkContainer"
         v-if="isLink">
      <iframeLink :ifmUrl="ifmUrl"
                  :clickStatus="clickStatus">
      </iframeLink>
    </div>
  </div>
</template>
   
<script>
import {
  getStepWareInfo,
  saveWatchLogStart,
  saveWatchLogEnd,
  getVideoLastPos,
  checkWareFinish,
  saveWatchLog
} from "@api/wxpx.js";
const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {};
import pdfView from "@components/pdfView/index.vue";
import zplayer from "./zPlayer.vue";
import iframeLink from "./link.vue";
import { Toast } from 'vant'
import { setData, getData, _throttle, createGguid } from "@/utils";
export default {
  components: {
    pdfView,
    zplayer,
    iframeLink
  },
  data () {
    return {
      userInfo,
      videoSrc: "",
      active: 2,
      isLoading: false,
      list: [],
      loading: false,
      finished: false,
      studyData: {},
      videoStatus: false,
      pdfStatus: false,
      pdfUrl: "",
      audioStatus: false,
      audioSrc: "",
      pdf: "",
      playerOptions: {
        controllBarMode: "click",
      },
      videoAutoid: 0,
      timer: null,
      guid: "",
      changeBeforeId: 0, //   组件切换前的id
      nowplaytime: 0, // 当前播放的时间总长 主要用于解决 视频切换的问题
      returnTime: 0,
      videoTagStatus: false,
      pdfId: 0,
      linkId: 0,
      audioId: 0,
      audioPlayer: null,
      firstUpLoad: false,
      studyTitle: "",
      isLink: false,
      ifmUrl: "",
      mautoid: this.$route.query.mautoid,
      clickStatus: "",
      dataIndex: 0,
      dataKey: 0,
    };
  },
  mounted () {
    const _this = this;
    // 首次加载 缓存guid
    if (window.localStorage.getItem("learningCoursesDataKey" + this.mautoid))
    {
      _this.dataKey = window.localStorage.getItem("learningCoursesDataKey" + this.mautoid);
    }
    if (window.localStorage.getItem("learningCoursesDataIndex" + this.mautoid))
    {
      _this.dataIndex = window.localStorage.getItem("learningCoursesDataIndex" + this.mautoid);
    }

    // 页面窗口关闭的时候 调用 更新状态
    window.addEventListener("pagehide", () => {
      var img = new Image();
      let baseUrl = window.BASE_URL;
      let apiUrl = "/api/wxpx/saveWatchLog_End";
      let data =
        "?empid=" +
        _this.userInfo.empid +
        "&mautoid=" +
        _this.$route.query.mautoid +
        "&cw_autoid=" +
        _this.videoAutoid +
        "&guid=" +
        _this.guid +
        "&videotimes=" +
        _this.nowplaytime +
        "&lastpos=" +
        getData(`xfyktVideoTime_${_this.videoAutoid}`) +
        "&username=" +
        _this.userInfo.username +
        "&isfinish=0";
      img.style.display = "none";
      img.src = baseUrl + apiUrl + data; // 服务端处理接口
      document.body.appendChild(img);
      localStorage.setItem("upload_time", new Date().getTime());
    });
  },
  methods: {
    // 当视频播放结束后 会自动调用暂停
    ended (options) {
      console.log(options, "播放完成！");
      this.recordVideoInfo(this.videoAutoid, this.nowplaytime, 1);
      // 还应该调用一个接口
    },
    // 记录时间
    // time是秒
    // 需要记录的视频id 可以是上一个播放的视频
    // status是状态 1是视频播放完整
    recordVideoInfo (id, time, status) {
      // 把时间转换为分钟 且只保留2位小数 不做四舍五入
      let minute = Math.floor((time / 60) * 100) / 100;
      if (id && minute && getData(`xfyktVideoTime_${id}`))
      {
        saveWatchLogEnd({
          empid: this.userInfo.empid,
          mautoid: this.$route.query.mautoid,
          cw_autoid: id,
          lastpos: getData(`xfyktVideoTime_${id}`),
          guid: this.guid,
          videotimes: minute,
          username: this.userInfo.username,
          isfinish: status,
        }).then((res) => {
          console.log("记录视频信息的变化");
        });
      }
    },
    // 播放停止 如视频之间的切换
    videoabort () {
      if (this.changeBeforeId && this.nowplaytime)
      {
        this.recordVideoInfo(this.changeBeforeId, this.nowplaytime, 0);
      }
    },
    // 播放暂停
    videopause (time) {
      this.recordVideoInfo(this.videoAutoid, time, 0);
    },
    // 播放开始
    videoplay (time) {
      this.nowplaytime = time;
      if (!time)
      {
        alert("网络延迟！当前播放状态，视频无法记录！");
        time = 0;
      }
      // 把时间转换为分钟 且只保留2位小数 不做四舍五入 videoAutoid
      let minute = Math.floor((time / 60) * 100) / 100;
      saveWatchLogStart({
        empid: this.userInfo.empid,
        mautoid: this.$route.query.mautoid,
        cw_autoid: this.videoAutoid,
        guid: this.guid,
        videotimes: minute,
        username: this.userInfo.username,
      })
        .then((res) => {
          console.log("记录播放开始的状态");
        })
        .catch((err) => {
          alert("接口出错了：saveWatchLogStart");
        });
    },
    // 视频的时间更新
    timeUpdate (time) {
      if (!time || this.timer) return false;
      this.timer = setTimeout(() => {
        clearTimeout(this.timer);
        this.timer = null;
      }, 1000);
      // localStorage.setItem(`xfyktVideoTime_${id}`, time)
      setData(`xfyktVideoTime_${this.videoAutoid}`, time);
    },
    getVideoLastPosTime (id) {
      getVideoLastPos({
        empid: this.userInfo.empid,
        mautoid: this.$route.query.mautoid,
        cw_autoid: id,
        username: this.userInfo.username,
      }).then((res) => {
        this.returnTime = res.data[0].lastpos;
      });
    },
    async checkWareIsFinish (id) {
      let res = await checkWareFinish({
        empid: this.userInfo.empid,
        mautoid: this.$route.query.mautoid,
        cw_autoid: id,
        username: this.userInfo.username,
      });
      return res.data[0].isfinish;
    },
    firstplay () {
      // 获取用户上次观看的时间点 然后更新进度
      this.setLastVideoTime();
    },
    // 设置用户上一次看到的进度
    setLastVideoTime () {
      if (this.returnTime)
      {
        setTimeout(() => {
          this.$refs.zplayer._setCurrentTime(
            Math.floor(Number(this.returnTime))
          );
        }, 100);
      } else
      {
        let res = this.findVideoLog(this.videoAutoid);
        setTimeout(() => {
          this.$refs.zplayer._setCurrentTime(Math.floor(Number(res)));
        }, 100);
      }
    },
    //找到该视频的记录
    findVideoLog (id) {
      return Number(getData(`xfyktVideoTime_${id}`)) || 0;
      // return Number(localStorage.getItem(`xfyktVideoTime_${id}`)) || 0
    },
    //添加签到的时间节点  当视频更换或者初始化的时候执行
    addSignIn (data) { },
    onClickLeft () {
      if (this.videoAutoid)
      {
        this.recordVideoInfo(this.videoAutoid, this.nowplaytime, 0);
      }
      if (this.clickStatus == 'iframe')
      {
        this.clickStatus = "";
        this.isLink = false;
      } else
      {
        this.$router.push("/toLearn");
      }

    },
    visitPdf () {
      saveWatchLog({
        empid: this.userInfo.empid,
        mautoid: this.$route.query.mautoid,
        cw_autoid: this.pdfId,
        username: this.userInfo.username,
      }).then((res) => {
        window.localStorage.setItem("learningCoursesDataIndex" + this.mautoid, this.dataIndex)
        window.localStorage.setItem("learningCoursesDataKey" + this.mautoid, this.dataKey)
        this.pdf = this.pdfUrl + "?time=" + new Date().getTime();
        // console.log("记录pdf开始的状态");
      });
    },
    visitLink () {
      if (!this.ifmUrl)
      {
        Toast(this.$t("learningCourses.CourseTxt13"));
      }
      saveWatchLog({
        empid: this.userInfo.empid,
        mautoid: this.$route.query.mautoid,
        cw_autoid: this.linkId,
        username: this.userInfo.username,
      }).then((res) => {
        window.localStorage.setItem("learningCoursesDataIndex" + this.mautoid, this.dataIndex)
        window.localStorage.setItem("learningCoursesDataKey" + this.mautoid, this.dataKey)
        this.clickStatus = 'iframe';
        this.isLink = true;
      });
    },
    audioPlay () {
      saveWatchLogStart({
        empid: this.userInfo.empid,
        mautoid: this.$route.query.mautoid,
        cw_autoid: this.audioId,
        guid: this.guid,
        videotimes: 0,
        username: this.userInfo.username,
      }).then((res) => {
        console.log("记录audio开始的状态");
      });
    },
    onClickRight () { },
    onLoad () {
      this.studyData = JSON.parse(
        window.sessionStorage.getItem("v8_study_data")
      );
      getStepWareInfo({
        empid: this.userInfo.empid,
        mautoid: this.$route.query.mautoid,
      }).then((res) => {
        console.log(res.data);
        console.log(typeof res.data);
        this.studyTitle = res.data[0].cwname;
        this.ifmUrl = "";
        let rdata = res.data;
        // this.dataKey
        // activeCls
        //  _this.dataIndex
        //  _this.dataIndex
        rdata.forEach((item, idx) => {
          item.active = false;
        });
        let mapdata = this.arrayToMap(rdata);
        this.list = this.mapToArray(mapdata);
        this.list[this.dataIndex][this.dataKey].active = true;
        let datacwtype = this.list[this.dataIndex][this.dataKey].cwtype;
        if (datacwtype == "视频")
        {
          this.guid = createGguid();
          this.videoAutoid = res.data[0].autoid;
          // this.videoTagStatus
          this.changeBeforeId = this.videoAutoid;
          this.checkWareIsFinish(this.videoAutoid).then((resss) => {
            if (resss)
            {
              // 播放完成
              this.videoStatus = false;
              setTimeout(() => {
                this.videoTagStatus = true;
                this.videoSrc = res.data[0].cwurl;
              }, 100);
            } else
            {
              this.videoTagStatus = false;
              this.videoStatus = true;
              this.getVideoLastPosTime(this.videoAutoid);
              this.firstUpLoad = false;
              setTimeout(() => {
                this.videoSrc = res.data[0].cwurl;
                this.firstUpLoad = true;
                this.$refs.zplayer.changeUrl(this.videoSrc);
              }, 100);
            }
          });
        } else if (datacwtype == "文档")
        {
          this.pdfUrl = res.data[0].cwurl;
          this.pdfId = res.data[0].autoid;
          this.pdfStatus = true;
        } else if (datacwtype == "语音")
        {
          this.audioSrc = res.data[0].cwurl;
          this.audioId = res.data[0].autoid;
          this.audioStatus = true;
          setTimeout(() => {
            this.audioPlayer = this.$refs.audioRefDom;
            // 监听视频是否正在播放
            this.audioPlayer.addEventListener("play", () => {
              this.audioPlay();
            });
          }, 100);
        } else if (datacwtype == "链接")
        {
          this.linkId = res.data[0].autoid;
          this.ifmUrl = res.data[0].cwurl;
        }
        console.log(this.list)
        this.loading = false;
        this.finished = true;
      });
    },
    arrayToMap (data) {
      //非数组或数据长度为0不处理
      if (!data instanceof Array || data.length == 0)
      {
        return {};
      }
      var map = {};
      for (var i = 0; i < data.length; i++)
      {

        // if (!data[i] instanceof Array || data.length < 2) {
        //   continue;
        // }
        let name = data[i].stagename;
        if (name != undefined)
        {
          if (map[name] == undefined)
          {
            map[name] = [];
          }
          map[name].push(data[i]);
        }

      }

      return map;
    },
    //把map转成数组
    mapToArray (data) {
      if (data == undefined)
      {
        return [];
      }
      let array = [];
      for (let p in data)
      {
        array.push(data[p]);
      }
      return array;
    },
    // 切换课件
    changeCourseware (val, index, key) {
      if (!val.cwurl)
      {
        Toast(this.$t("learningCourses.CourseTxt13"));
        return false;
      }
      this.studyTitle = val.cwname;
      this.ifmUrl = "";
      if (val.cwtype == "视频")
      {
        this.guid = createGguid();
        this.changeBeforeId = this.videoAutoid;
        this.pdfStatus = false;
        this.audioStatus = false;
        this.isLink = false;
        this.videoAutoid = val.autoid;
        this.recordVideoInfo(this.changeBeforeId, this.nowplaytime, 0);
        this.checkWareIsFinish(this.videoAutoid).then((resss) => {
          if (resss)
          {
            // 播放完成
            this.videoStatus = false;
            this.videoTagStatus = false;
            setTimeout(() => {
              this.videoTagStatus = true;
              this.videoSrc = val.cwurl;
            }, 100);
          } else
          {
            this.videoTagStatus = false;
            this.videoStatus = true;
            this.getVideoLastPosTime(this.videoAutoid);
            this.firstUpLoad = false;
            setTimeout(() => {
              this.videoSrc = val.cwurl;
              this.firstUpLoad = true;
              this.$refs.zplayer.changeUrl(this.videoSrc);
            }, 100);
          }
        });
      } else if (val.cwtype == "文档")
      {
        this.pdfStatus = false;
        this.videoStatus = false;
        this.audioStatus = false;
        this.videoTagStatus = false;
        this.isLink = false;
        this.pdfId = val.autoid;
        if (this.videoAutoid && this.nowplaytime)
        {
          // 如果之前播放视频
          this.recordVideoInfo(this.videoAutoid, this.nowplaytime, 0);
        }
        setTimeout(() => {
          this.pdfUrl = val.cwurl;
          this.pdfStatus = true;
          this.dataIndex = index;
          this.dataKey = key;
        }, 100);
      } else if (val.cwtype == "语音")
      {
        if (this.videoAutoid && this.nowplaytime)
        {
          // 如果之前播放视频
          this.recordVideoInfo(this.videoAutoid, this.nowplaytime, 0);
        }
        this.pdfStatus = false;
        this.videoStatus = false;
        this.audioStatus = false;
        this.videoTagStatus = false;
        this.isLink = false;
        this.audioId = val.autoid;
        setTimeout(() => {
          this.audioSrc = val.cwurl;
          this.audioStatus = true;
          setTimeout(() => {
            this.audioPlayer = this.$refs.audioRefDom;
            // 监听视频是否正在播放
            this.audioPlayer.addEventListener("play", () => {
              this.audioPlay();
            });
          }, 100);
        }, 100);
      } else if (val.cwtype == "链接")
      {
        this.ifmUrl = val.cwurl;
        this.linkId = val.autoid;
        this.pdfStatus = false;
        this.videoStatus = false;
        this.audioStatus = false;
        this.videoTagStatus = false;
        this.clickStatus = 'iframe'
        // this.isLink = true;
        this.dataIndex = index;
        this.dataKey = key;
      }

      this.list.forEach((item) => {
        item.forEach((vals) => {
          vals.active = false;
        });
      });
      val.active = true;
      // 如果有视频就改变video地址，没有视频就占位
    },
  },
};
</script>
<style lang="less" scoped>
.learningCourses {
  width: 100%;
  height: 100%;
  overflow: auto;
  .linkContainer {
    padding-top: 0;
    width: 100%;
    height: 1px;
  }
  .van-nav-bar {
    background: #2b8df0;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }
  .tabsDiv {
    ::v-deep .van-tab {
      font-size: 32px;
      font-family: Source Han Sans CN;
    }
    h2 {
      margin: 0;
      padding: 15px 28px;
      font-size: 32px;
      font-weight: 500;
      background: #f1f1f1;
    }
    .CoursesList {
      padding: 28px;
      .van-cell__value {
        position: unset !important;
        display: flex;
        .leftbox {
          width: 240px;
          height: 130px;
          margin-right: 18px;
          position: relative;
        }
        img {
          width: 240px;
          height: 130px;
        }
        .info {
          flex: 1;
          .description {
            color: #999999;
            font-size: 24px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            line-height: 36px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }
          .footer {
            display: flex;
            justify-content: space-between;
            // align-items: flex-end;
            align-items: baseline;
            .stageName {
              width: 78%;
              margin: 0;
              font-size: 28px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #333333;
            }
            .right {
              .length {
                font-size: 20px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                line-height: 27px;
                color: #2b8df0;
              }
            }
          }
          .top {
            display: flex;
            justify-content: flex-end;
            color: #2b8df0;
            font-weight: bold;
          }
        }
      }
    }
    .examList {
      margin: 20px 0;
      .van-cell__value {
        display: flex;
        .info {
          flex: 1;
          .lecturerName {
            color: #999999;
            font-size: 28px;
            font-family: Source Han Sans CN;
            font-weight: 400;
          }
          .footer {
            display: flex;
            justify-content: space-between;
            // align-items: flex-end;
            align-items: baseline;
            .courseName {
              width: 70%;
              margin: 0;
              font-size: 32px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              // line-height: 54px;
              color: #333333;
              // opacity: 1;
            }
            .date {
              color: #999999;
              font-size: 24px;
            }
            .right {
              .score {
                font-size: 32px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                line-height: 27px;
                color: #2b8df0;
              }
              .duration {
                font-size: 32px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                line-height: 27px;
                color: #333333;
              }
            }
          }
        }
      }
    }
    .descriptionUl {
      background: #fff;
      padding: 20px 30px;
      li {
        text-indent: 2em;
        font-size: 32px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 54px;
        color: #333333;
        opacity: 1;
      }
    }
  }
  .commentList {
    .van-card:first-child {
      margin-top: 16px;
    }
    .van-card {
      background: #fff;
      .van-card__thumb {
        margin-right: 30px;
        width: 74px;
        height: 74px;
        img {
          width: 74px;
          height: 74px;
        }
      }
      .van-card__content {
        .name {
          display: flex;
          justify-content: flex-end;
          span:first-child {
            margin-right: auto;
            font-size: 28px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #666666;
          }
          .svg_icon {
            display: flex;
            align-items: center;
            span {
              margin: 0 20px;
            }
          }
        }
        .van-card__desc {
          font-size: 24px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #999999;
        }
        .content {
          padding-top: 20px;
          font-size: 32px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          line-height: 48px;
          color: #333333;
          opacity: 1;
        }
        .imgs {
          ::v-deep .van-grid-item__content {
            padding: 15px 4px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        .comments {
          height: 80px;
          background: #eceaea;
          opacity: 1;
          border-radius: 4px;
          display: flex;
          align-items: center;
          span {
            font-size: 28px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            line-height: 48px;
            opacity: 1;
          }
          span:first-child {
            padding-left: 20px;
            color: #2b8df0;
          }
          span:last-child {
            padding-left: 20px;
            color: #666666;
          }
        }
      }
    }
  }
  .entry_button {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 120px;
    padding: 20px;
    box-sizing: border-box;
    background: #fff;
    ::v-deep input {
      padding: 0 40px;
      height: 64px;
      background: #f4f4f4;
      border-radius: 44px;
    }
  }
}
.activeCls {
  background: #dee7f1 !important;
}
.kjlx {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 10;
  padding: 5px 10px;
  background: rgb(121, 0, 255, 0.8);
  color: #fff;
  font-size: 12px;
  border-radius: 5px 0 0 0;
}
.videoDiv {
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #ccc;
}
.pdfClass {
  width: 100%;
  height: 100%;
  a {
    width: 100%;
    height: 100%;
    display: block;
  }
  .pdfDiv {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .pdfContent {
    text-align: center;
    p {
      margin: 0;
      width: 100%;
      text-align: center;
      font-size: 32px;
      margin-top: 20px;
    }
  }
}
.infoExamIcon {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 99999;
  width: 90px;
  img {
    width: 90px !important;
    height: auto !important;
  }
}
.svg-icon {
  width: 1.5rem;
  height: 1.5rem;
}
</style>              